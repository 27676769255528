<template>
  <router-view></router-view>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('sites', ['active_brand', 'active_site']),
    loading() {
      if (!this.active_brand) return true;
      return this.active_brand.id !== this.$route.params.brand_id;
    },
  },
  async mounted() {
    this.init(); // no await to load faster
  },
  methods: {
    async init() {
      if (!this.loading) return;
      try {
        await this.$store.dispatch('sites/fetchBrand', {
          id: this.$route.params.brand_id,
        });
        this.$store.dispatch('sites/fetchBrand', {
          id: this.$route.params.brand_id,
          nocache: true,
        });
      } catch (err) {
        console.error('could not fetch brand', err);
        this.$toast('fetching brand failed, trying again');
        await this.$store.dispatch('sites/fetchBrand', { id: this.$route.params.brand_id });
      }
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 2,
        title: this.active_brand.name,
        to: {
          name: 'brand-overview-menus',
          params: { site_id: this.$route.params.site_id, brand_id: this.$route.params.brand_id },
        },
        last: false,
      });
    },
  },
};
</script>

<style></style>
